import Vue from "vue";
import Router from "vue-router";
const je = require("json-encrypt");

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

const Forms = () => import("@/views/base/Forms");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const userGuard = (to, from, next) => {
  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");

  if (user == null || user == undefined) {
    next("/");
  } else {
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
      next("/");
    } else {
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: "Page404" });
      } else {
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true;
      }
      if (!isAuthenticated) next({ name: "Page404" });
      else next();
    }
  }
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          name: "LoginPage",
          component: Login,
        },
      ],
    },
    {
      path: "/inicio",
      redirect: "/inicio/inicio",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "inicio",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },

    {
      path: "/usuario",
      redirect: "/usuario/listar",
      name: "Usuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserList",
          component: () => import("@/views/user/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserAdd",
          component: () => import("@/views/user/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user",
          name: "UserEdit",
          component: () => import("@/views/user/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user",
          name: "UserView",
          component: () => import("@/views/user/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "profile",
          name: "UserProfile",
          component: () => import("@/views/pages/Profile"),
          props: true,
        },
      ],
    },

    {
      path: "/tipo-usuario",
      redirect: "/tipo-usuario/listar",
      name: "TipoUsuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserTypeList",
          component: () => import("@/views/usertype/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserTypeAdd",
          component: () => import("@/views/usertype/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user_type",
          name: "UserTypeEdit",
          component: () => import("@/views/usertype/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user_type",
          name: "UserTypeView",
          component: () => import("@/views/usertype/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/huesped",
      redirect: "/huesped/listar",
      name: "Guest",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "GuestList",
          component: () => import("@/views/guest/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "GuestAdd",
          component: () => import("@/views/guest/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_guest",
          name: "GuestEdit",
          component: () => import("@/views/guest/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_guest",
          name: "GuestView",
          component: () => import("@/views/guest/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/tipo-habitacion",
      redirect: "/tipo-habitacion/listar",
      name: "RoomType",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "RoomTypeList",
          component: () => import("@/views/room-type/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "RoomTypeAdd",
          component: () => import("@/views/room-type/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_room_type",
          name: "RoomTypeEdit",
          component: () => import("@/views/room-type/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_room_type",
          name: "RoomTypeView",
          component: () => import("@/views/room-type/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/servicio",
      redirect: "/servicio/listar",
      name: "Service",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ServiceList",
          component: () => import("@/views/service/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ServiceAdd",
          component: () => import("@/views/service/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_service",
          name: "ServiceEdit",
          component: () => import("@/views/service/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_service",
          name: "ServiceView",
          component: () => import("@/views/service/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/cama",
      redirect: "/cama/listar",
      name: "Bed",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BedList",
          component: () => import("@/views/bed/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BedAdd",
          component: () => import("@/views/bed/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_bed",
          name: "BedEdit",
          component: () => import("@/views/bed/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_bed",
          name: "BedView",
          component: () => import("@/views/bed/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/tarifa",
      redirect: "/tarifa/listar",
      name: "Rate",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "RateList",
          component: () => import("@/views/rate/List"),
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/reserva",
      redirect: "/reserva/listar",
      name: "Reservation",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ReservationList",
          component: () => import("@/views/reservation/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ReservationAdd",
          component: () => import("@/views/reservation/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_reservation",
          name: "ReservationEdit",
          component: () => import("@/views/reservation/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_reservation",
          name: "ReservationView",
          component: () => import("@/views/reservation/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


  ];
}
